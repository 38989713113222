import { motion } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { mq } from '../../styles/globalstyles'
import Img from 'gatsby-image';



export const PartnerSlider = () => {
    const data = useStaticQuery(graphql`
        query{
            allFile(
                filter: {
                  extension: { regex: "/(jpg)|(png)|(jpeg)/" }
                  relativeDirectory: { eq: "startseite" }
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      fixed(height: 60, grayscale: false) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }

            }
        `)

    return ( 
        <motion.div css={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", flexWrap: "wrap", gap: "3.6rem"}}>
            {data.allFile.edges.map((image, index) => (
            <Img
                key={index}
                css={mq({margin: "1em 0.5em", padding: ["0.4em 0.4em","0.4em 0.4em","0.4em 0.4em", "0.4em 0.4em"]})}
                fixed={image.node.childImageSharp.fixed}
                alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
            />
            ))}
        	

        </motion.div>


    )
        
    
    
    }

