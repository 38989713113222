import { gql } from "@apollo/client";

const BLOG = gql`
{
blogs(stage: PUBLISHED, orderBy: datum_DESC, first: 3) {
  titel 
  id
  untertitel 
  vorschautext
  vorschaubild{
    url(transformation: {  document: { output: { format: webp } }, image: {resize: {width: 700, height: 510, fit: crop}}})
  }
  blogAutoren {
    vorname
    nachname
    titel
    id
  }
  datum
  header{
    url
    }
  }
abendveranstaltungen(where: {startseite: true}) {
  titel
  slug
  untertitel
  kostenbeitrag
  veranstalter
  speaker {
    id
  },
  termine{
    termin
    veranstaltungsort
  }
  },
tagesseminare(where: {startseite: true}) {
  titel
  slug
  untertitel
  kostenbeitrag
  veranstalter
  speaker {
    id
  },
  termine{
      termine
      veranstaltungsort
  }
  },
onlineevents(where: {startseite: true}) {
  titel
  slug
  untertitel
  kostenbeitrag
  veranstalter
  speaker {
    id
  },
  termine{
    termin
    }
  },
  startseites {
    startseiteHeader {
      html
    }
    startseite1 {
      html
    }
    startseiteAkademie {
      html
    }
    startseiteBlog {
      html
    }
    startseiteNewsletter {
      html
    }
    textBlockUeberschrift {
      html
    }
    textBlock1 {
      html
    }
    textBlock2 {
      html
    }
    textBlock3 {
      html
    }
  },
  teams(where: {teamseite: true}) {
    titel
    vorname
    nachname
    id
    portrait{
        id
        url
    }
    eMail
}
}`

export default BLOG;