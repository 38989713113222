
import React from 'react';
import {motion} from 'framer-motion';
import {Link} from 'gatsby';

import {css} from '@emotion/react';

import { white, dark, FlexBoxRow, akademiecardreferents, akademiecarddate, akademiecardtitle, eventcardsmall, eventcard, mq } from '../../../styles/globalstyles';
import { EventSpeaker } from '@components/events';
import { buttonmedium } from '@components/buttons/styles';

const StartEventCard = ({ props:{termine}, props, position}) =>{
    let sortedDates;
    let d;
    let date;
    let place;
    let end;
    let dateplace =[];
    let onlydate =[];
    const terminCopy =[...termine]
    console.log(termine);
      if (termine[0].termin) {
        sortedDates =  terminCopy.sort((a, b) => new Date(a.termin) - new Date(b.termin))
         d = new Date(sortedDates[0].termin)
      }
      else if (termine[0].termine) {
        for (let i = 0; i < termine.length; i++ ) {
          let n = termine[i].termine.length
          let p = termine[i].veranstaltungsort
          
          date = new Date(termine[i].termine[0] )
          if (n>1) {  end = new Date(props.event.termine[i].termine[n-1])
            dateplace.push(date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() + " bis " + end.getDate() + "." + (end.getMonth() + 1) + "." + end.getFullYear() +  " in " + p)
            onlydate.push(date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() + " bis " + end.getDate() + "." + (end.getMonth() + 1) + "." + end.getFullYear())
          }
          else {
            dateplace.push(date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() + " in " + p)
            onlydate.push(date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear())
          }  
        }
        
        }
        return(
            <motion.div whileTap={{scale: 0.99}} className="buttonShadow" initial="initial" animate="enter"  transition={{duration: 0.2}}  css={[eventcard, {top: position * "14em", height: "auto"}]}> 
                   <Link to={"/akademie/" + props.slug} css={mq({position: "absolute", display: ["none", "none", "block"], width:"100%", height: "100%", opacity: 0, zIndex: 2})} > 
                   {props.titel}
                   </Link>
                   <FlexBoxRow >  
                    <motion.div css={akademiecardtitle}>
                      <h6 css={css`margin-bottom: 1.2rem;`}>{props.__typename}</h6>
                      <h3 css={css`margin-bottom: 1.2rem !important; `}>{props.titel}</h3>
                      <p css={mq({color: dark, display: ["none", "none", "block"]})}>{props.untertitel}  </p>
                    </motion.div>
                    
                    <motion.div css={akademiecardreferents}>
                      {props.speaker.map((value, index) =>{
                        return <EventSpeaker id={value.id} key={index}titel="Referent" />
                      })
                      }
                    </motion.div>
                    {(d) ? 
                    <motion.div css={akademiecarddate}>
                      <div>
                      <div css={mq({fontSize: ["13px", "14px", "15px"]})}>
                        <h6>Datum</h6><p>{d.getDate() + "." + (d.getMonth() +1) + "." + d.getFullYear()}</p> </div>
                        {(sortedDates[0].veranstaltungsort) ? <div><h6>Ort</h6><p>{sortedDates[0].veranstaltungsort}</p> </div> : null}
                      </div>
                      <Link to={"/akademie/" + props.slug}>
                        <button className="buttonShadow" css={buttonmedium} >Zur Veranstaltung</button>
                      </Link>
                    </motion.div> : 
                    
                    <motion.div css={akademiecarddate}>
                        <div> 
                        <div>
                          <h6>Datum</h6><p>{onlydate[0]}</p> </div>
                        {(place) ? <div><h6>Ort</h6><p>{place}</p> </div> : null}
                        </div>  
                        <Link to={"/akademie/" + props.slug}>
                          <button className="buttonShadow" css={buttonmedium}>Zur Veranstaltung</button>
                        </Link>
                      </motion.div>
                  }
                  </FlexBoxRow>
                
            </motion.div> 
        )
      }

export const EventCardAkademie = ({props}) =>{
    let d;
    //  if (props.termin) {
      //  sortedDates =  termine.sort((a, b) => new Date(a.termin) - new Date(b.termin))
  d = new Date(props.termin)
        return(
          <motion.div className="buttonShadow" initial="initial" animate="enter" variants={variants} transition={{duration: 0.2}}  css={eventcard}> 
                  <Link to={"/akademie/" + props.event.slug} css={{position: "absolute", width:"100%", height: "100%", opacity: 0, zIndex: 2}} > 
                   {props.event.titel}
                   </Link>
                  <FlexBoxRow >  
                  <motion.div css={akademiecardtitle}>
                        <h6 css={css`margin-bottom: 1.4em;`}>Abendveranstaltung</h6>
                        <h3 css={css`margin-bottom: 1.0em !important; `}>{props.event.titel}</h3>
                        <p css={css`color: ${dark};`}>{props.event.untertitel}  </p>
                  </motion.div>
                  
                  <motion.div css={akademiecardreferents}>
                        {props.event.speaker.map((value, index) =>{
                          return <EventSpeaker id={value.id} key={index}titel="Referent" />
                        })
                        }
                  </motion.div>
                  {(d) ? 
                  <motion.div css={akademiecarddate}>
                      <div> 
                      <div><h6>Datum</h6><p>{d.getDate() + "." + (d.getMonth() +1) + "." + d.getFullYear()}</p> </div>
                      {(props.veranstaltungsort) ? <div><h6>Ort</h6><p>{props.veranstaltungsort}</p> </div> : null}
                      </div>
                       <button className="buttonShadow" css={buttonmedium}>Zur Veranstaltung</button>
                  </motion.div> : <motion.div> <h6>Gegenwärtig Keine Termine</h6></motion.div>
                }
                </FlexBoxRow>
          </motion.div> 
        )
      }

export default StartEventCard