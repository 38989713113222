const { gql } = require("@apollo/client");

const EVENTS = gql`
query eventquerystart($date: DateTime!, $startseite: Boolean!)
{
  abendveranstaltungTermine(stage: PUBLISHED, where: {termin_gt: $date, event: {startseite: $startseite}}) {
    id
    stage
    veranstaltungsort
    termin
    endzeit
    event {
      id
      startseite
      titel
      slug
      untertitel
      kurzbeschreibung {
        html
      }
      kostenbeitrag
      veranstalter
      termine {
        id
        termin
      }
      speaker {
        id
      }
    }
  },
  onlineEventTermine(stage: PUBLISHED, where: {termin_gt: $date, event: {startseite: $startseite}}) {
    id
    stage
    termin
    endzeit
    event {
      id
      titel
      startseite
      slug
      untertitel
      kurzbeschreibung {
        html
      }
      kostenbeitrag
      veranstalter
      termine {
        id
        termin
      }
      speaker {
        id
      }
    }
  },
  tagesseminarTermine(stage: PUBLISHED, where: {event: {startseite: $startseite}}) {
    id
    stage
    termine
    event {
      id
      titel
      startseite
      slug
      untertitel
      kurzbeschreibung {
        html
      }
      termine {
        id
        termine
        veranstaltungsort
      }
      kostenbeitrag
      veranstalter
      speaker {
        id
      }
    }
  }
}
`

export default EVENTS;