import React from'react';
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill";
import {FlexBox, FlexBoxColumn, FlexContainerRow, mq, orange} from "../../styles/globalstyles"
import KontaktForm from "./kontakt-form"


const ContactFormSite = () =>{
    const images = useStaticQuery(graphql`
    query {
        kontakticon: file(relativePath: {eq: "grafiken/kontakt_shadow.png"}) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 50) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }

    `)

    return (
        <FlexContainerRow >
            <FlexBox justify="space-between" direction="column" css={mq({height: "100%", alignItems: ["center", "center", "center", "flex-start"]})}>
                <div>
                    <h2>Starten Sie jetzt Ihren Kulturwandel</h2>
                    <p>Schreiben Sie an <a css={{color: orange + " !important", textDecoration: "underline !important"}} href="mailto:kontakt@proceed.gmbh">kontakt@proceed.gmbh </a></p>
                </div>
                <Img fluid={images.kontakticon.childImageSharp.fluid} alt="Kontakt" objectFit="cover" css={mq({width: "100%", height: "auto", maxWidth: "12em", marginBottom: "2em"})} />
            </FlexBox>
          <FlexBoxColumn>
            <KontaktForm />
          </FlexBoxColumn>

        </FlexContainerRow>
    )
} 

export default ContactFormSite