import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { buttonmedium } from '../../styles/buttons'
import { mq, orange, white } from '../../styles/globalstyles'
import Icons from '../icons'
import Image from '../image'

const slideVariants = {
    animate: {
        left: "100%",
        x: "-100%",
        transition: {ease: "easeOut", delay: 0.4, duration: .5 }
    },
    initial: {
        left: "100.2%",
        x: 0,
        transition: {ease: "easeOut", delay: 0.4, duration: .5 }
    }
}

const slide = mq({
    top: "40vh",
    position: "fixed",
    left: "110%",
    height:["24em", "24em", "30em"],
    maxHeight: "55vh",
    width: ["12em", "12em", "18em"],
    background: white,
    zIndex: 8,
    borderRadius: "6px 0 0 0",
    padding: "1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
})

const Whitebook = () => {
    const [show, setShow] = useState(true)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, false);
        return () => window.removeEventListener("scroll", handleScroll, false);
        },[]);
    const handleScroll = (e) => (window.scrollY > 200 ? setShow(false) : setShow(true))

    return (
        <motion.div initial="initial"  css={slide} className="shadow" variants={slideVariants} animate={show ? "animate": "initial"}>
            <h3>
                Jetzt bestellbar
            </h3>
            <div css={{height: "auto", flex: "1 2 80%", width: "100%"}}>
                <Image image="weissbuch" />
            </div>
            <Link to="/wissenswertes" state={{state: "whitebook"}} css={{flex: "1 1 auto"}}>
                <button className="buttonShadow" css={buttonmedium} > zum Bestellformular </button>
            </Link>
            
            <div onClick={() => setShow(!show)} className="shadow" css={mq({background: orange, position: "absolute", bottom: 0, left: 0, zIndex: 7, height: ["40px", "45px", "50px"], width: ["40px", "45px", "50px"], transform: "translateX(-100%)", borderRadius: "6px 0 0 6px", padding: "0.3rem", cursor: "pointer"})}>
                <Icons icon="form" />
            </div>
        </motion.div>
    )
}

export default Whitebook

