import React from 'react';
import StartEventCard from './StartEventCard';
import { useQuery } from '@apollo/client';
import EVENTS from '../constants/EVENTS';
import appStyles from '@appStyles';
import { motion } from 'framer-motion';

const date = new Date().toISOString()
const startseite = true ;

const StartEvents = () => {

    const {error, loading, data} = useQuery(EVENTS, {variables: { date, startseite } })
    let sortedEvents = [];

    if (data)  {
        const abendvernanstaltungen = data.abendveranstaltungTermine.map((values, index) =>{
            return {termin: new Date(values.termin), id: values.event.id, titel: values.event.titel, event: values.event}
            })
        const onlineevents = data.onlineEventTermine.map((values, index) =>{
            return {termin: new Date(values.termin), id: values.event.id, titel: values.event.titel, event: values.event}
            })
        const tagessemniare = data.tagesseminarTermine.map((values, index) =>{
            return {termin: new Date(values.termine[0]), id: values.event.id, titel: values.event.titel, event: values.event}
            })

        const allevents =  abendvernanstaltungen.concat(onlineevents, tagessemniare)

        sortedEvents = allevents.sort((a, b) => a.termin - b.termin)  
    
        }

        if (sortedEvents.length > 0) return (
            <div css={appStyles.mq({marginBottom: "2em", display: "flex", flexDirection: "column", width: "100%", height: ["auto"], position: "relative", overflow: "visible"})}>
    
             <div css={{overflowX: "visible"}}>
             {(sortedEvents) &&
                <div css={{overflow: "visible"}}> 
                    <motion.div id="eventcontainer" css={{position: "relative", height: "auto", width: "100%", overflowX: "visible", padding: "0 1rem"}}>
                        { sortedEvents.map((value, index) =>  {
                            return <StartEventCard props={value.event} key={index} position={index} />
                        }) }
                    </motion.div>
                </div>
            }
            </div>
        </div>
    ) 
    return null;
}

export default StartEvents